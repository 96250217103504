<footer id="contact">
  <div class="left">
    <div class="top">
      <h1>KONTAKTUJTE NÁS</h1>
      <app-contact-form></app-contact-form>
    </div>
    <div class="bottom">
      <a href="https://mapy.cz/s/forebufale" target="_blank">
        <span>UKAZÁT NA MAPĚ</span>
      </a>
    </div>
  </div>
  <div class="right">
    <div class="informations">
      <ul *ngIf="footerLink != null">
        <li>
          <div class="legend">
            <h3>obec</h3>
          </div>
          <div class="information">
            <p>
              Janov 216, 569 55,<br> Janov u Litomyšle
            </p>

            <div *ngFor="let item of hack(footerLink)">
              <ng-container *ngIf="item.Type == 'Village'">
                <a [href]="item.ContactValue" target="_blank">{{item.Title}}</a>
              </ng-container>
            </div>
          </div>
        </li>
        <li>
          <div class="legend">
            <h3>telefon</h3>
          </div>
          <div class="information">
            <div *ngFor="let item of hack(footerLink)">
              <ng-container *ngIf="item.Type == 'Phone'">
                <a [href]="item.ContactValue" target="_blank">{{item.Title}}</a>
              </ng-container>
            </div>
          </div>
        </li>
        <li>
          <div class="legend">
            <h3>email</h3>
          </div>
          <div class="information">
            <div *ngFor="let item of hack(footerLink)">
              <ng-container *ngIf="item.Type == 'Email'">
                <a [href]="item.ContactValue" target="_blank">{{item.Title}}</a>
              </ng-container>
            </div>
          </div>
        </li>
        <li>
          <div class="legend">
            <h3>sociální svět</h3>
          </div>
          <div class="information">
            <div *ngFor="let item of hack(footerLink)">
              <ng-container *ngIf="item.Type == 'SocialSites'">
                <a [href]="item.ContactValue" target="_blank">{{item.Title}}</a>
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
      <div class="bottom-about">
        <span>
          na svět přivedlo
        </span>
        <a href="https://twentio.cz" target="_blank">
        </a>
      </div>
    </div>
  </div>
</footer>
