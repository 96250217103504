import { Component, OnInit } from '@angular/core';

export interface Categories {
  shortcut: string;
  title: string;
}

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  categories: Categories[]=[
    {
      shortcut: 'MD',
      title: 'Motor diesel',
    },
    {
      shortcut: 'MA',
      title: 'Motor benzínový',
    },
    {
      shortcut: 'MM',
      title: 'Motor motocykl',
    },
    {
      shortcut: 'BUG',
      title: 'Bugina',
    }
  ]

}
