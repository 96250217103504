import { Component, OnInit } from '@angular/core';

export interface HistoryItems {
  id: number,
  season: number;
  date: string;
  themeImage: string;
  data: Links[];
}

export interface Links {
  type: string;
  link: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  data: HistoryItems[] = [{
    id: 0,
    season: 1,
    date: "1.8.2009",
    themeImage: "/assets/images/season/rocnik_1.jpg",
    data: [{
      type: "photo",
      link: "https://www.rajce.idnes.cz/a2344645/mini?bgcolor=&photoNameVisible=0"
    }]
  },
  {
    id: 1,
    season: 2,
    date: "14.8.2010",
    themeImage: "/assets/images/season/rocnik_2.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/2-janovska-traktoriada-40"
    }]
  },
  {
    id: 2,
    season: 3,
    date: "30.7.2011",
    themeImage: "/assets/images/season/rocnik_3.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/3-janovska-traktoriada-63"
    },
    {
      type: "video",
      link: "https://www.youtube.com/embed/gN6GU0Bc8aw"
    }]
  },
  {
    id: 3,
    season: 4,
    date: "4.8.2012",
    themeImage: "/assets/images/season/rocnik_4.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/4-janovska-traktoriada-81"
    },
    {
      type: "photo",
      link: "https://svitavsky.denik.cz/galerie/traktoriada_janov_0810.html"
    },
    {
      type: "photo",
      link: "https://www.rajce.net/a6877549/mini?bgcolor=&photoNameVisible=0"
    },
    {
      type: "video",
      link: "https://www.youtube.com/embed/Fjau0ZbvCX0"
    },
    {
      type: "video",
      link: "https://www.youtube.com/embed/og878j8DwYw"
    },
    ]
  },
  {
    id: 4,
    season: 5,
    date: "30.8.2013",
    themeImage: "/assets/images/season/rocnik_5.jpg",
    data: [{
      type: "video",
      link: "https://www.youtube.com/embed/mtvOeSjkEYA"
    },
    {
      type: "photo",
      link: "http://www.minitractors.wbs.cz/Traktoriada-JANOV-2013---Fotogalerie.html"
    }]
  },
  {
    id: 5,
    season: 6,
    date: "2.8.2014",
    themeImage: "/assets/images/season/rocnik_6.jpg",
    data: [{
      type: "video",
      link: "https://www.youtube.com/embed/bBWsNN-5qlw"
    },
    {
      type: "video",
      link: "https://www.youtube.com/embed/idusGBJEvwg"
    },
    {
      type: "photo",
      link: "https://janov-sy.cz/galerie/6-janovska-traktoriada-111"
    },
    {
      type: "photo",
      link: "https://www.rajce.net/a10222131/mini?bgcolor=&photoNameVisible=0"
    },
    {
      type: "photo",
      link: "http://www.minitractors.wbs.cz/Traktoriada-JANOV-2014---Fotogalerie.html"
    },
    {
      type: "photo",
      link: "https://www.rajce.net/a10201696/mini?bgcolor=&photoNameVisible=0"
    },]
  },
  {
    id: 6,
    season: 7,
    date: "1.8.2015",
    themeImage: "/assets/images/season/rocnik_7.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/7-janovska-traktoriada-129"
    },
    {
      type: "photo",
      link: "https://www.rajce.net/a11714573/mini?bgcolor=&photoNameVisible=0"
    }]
  },
  {
    id: 7,
    season: 8,
    date: "6.8.2016",
    themeImage: "/assets/images/season/rocnik_8.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/8-janovska-traktoriada-142"
    }]
  },
  {
    id: 8,
    season: 9,
    date: "5.8.2017",
    themeImage: "/assets/images/season/rocnik_9.jpg",
    data: [{
      type: "",
      link: ""
    }]
  },
  {
    id: 9,
    season: 10,
    date: "4.8.2018",
    themeImage: "/assets/images/season/rocnik_10.jpg",
    data: [{
      type: "photo",
      link: "https://janov-sy.cz/galerie/10-janovska-traktoriada-164"
    }]
  },
  {
    id: 10,
    season: 11,
    date: "3.8.2019",
    themeImage: "/assets/images/season/rocnik_11.jpg",
    data: [{
      type: "video",
      link: "https://www.youtube.com/embed/4xedMUygzdM"
    },
    {
      type: "photo",
      link: "https://janov-sy.cz/galerie/11-janovska-traktoriada-177"
    }]
  }, {
    id: 11,
    season: 12,
    date: "se z epidemiologických opatření nekonal",
    themeImage: "/assets/images/season/rocnik_12.jpg",
    data: []
  },
  {
    id: 12,
    season: 13,
    date: "se z epidemiologických opatření nekonal",
    themeImage: "/assets/images/season/rocnik_13.jpg",
    data: []
  }
  ];
  constructor() { }


  ngOnInit(): void {
  }

}
