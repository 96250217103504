<div class="sponsors" id="sponsors">
  <h3>Sponzoři</h3>
  <div class="custom-container">
    <div class="owl-carousel owl-theme">
      <div class="item">
        <img src="../../../../../assets/images/sponsors/janov.png">
      </div>
    </div>
  </div>
</div>
