<form>
    <div class="tab">
      <div class="is-floating-label">
        <label>Jméno a příjmení</label>
        <input name="fullname" required="" type="text" [(ngModel)]="contactData.FullName" >
      </div>
      <div class="is-floating-label">
        <label>E-mail</label>
        <input name="email" [(ngModel)]="contactData.Email" required="" type="email">
      </div>
      <div class="is-floating-label">
        <label>Telefon</label>
        <input name="phonenumber" [(ngModel)]="contactData.PhoneNumber" required="" type="number"
          pattern="^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$">
      </div>
    </div>
    <div class="under">
      <div class="is-floating-label">
        <label>Vaše zpráva</label>
        <textarea name="message" [(ngModel)]="contactData.Message"></textarea>
      </div>
      <button (click)="sendEmail()">Odeslat</button>
    </div>
  </form>