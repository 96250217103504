import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Router, NavigationEnd } from '@angular/router';

declare var $: any;

const GET_CONTACTS = gql`
  {
    contactsFooters {
      Title
      Type
      ContactValue
    }
  }
`;

export interface FooterLinks {
  Title: string;
  Type: TypeOfLink;
  ContactValue: string;
}


enum TypeOfLink {
  Village = "Village",
  Phone = "Phone",
  Email = "Email",
  SocialSites = "SocialSites"
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  footerLink: FooterLinks[];
  private querySubscription: Subscription;

  constructor(
    private apollo: Apollo,
  ) { }

  ngOnInit() {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_CONTACTS
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.footerLink = data.contactsFooters;
      });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  hack(val) {
    val = Array.from(val);
    return val;
  }
}
