<div class="error">
    <div class="computer">
      <div class="content">
        <div class="navbar">
          <div class="dots">
            <a routerLink="/" routerLinkActive="active">
            </a>
            <a routerLink="/galerie" routerLinkActive="active">
            </a>
            <a href="https://twentio.cz" target="_blank">
            </a>
          </div>
        </div>
        <div class="white"></div>
        <div class="message">
          <h2>TATO STRÁNKA</h2>
          <h1>404</h1>
          <h2>NEEXISTUJE</h2>
        </div>
      </div>
    </div>
  </div>
  