import { Component, OnInit, Input } from '@angular/core';
import {ArticleData} from '../../view/article/article.component'
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent implements OnInit {

  @Input() articleData:ArticleData;   
  constructor() { }

  ngOnInit(): void {
  }

  returnDate(date){
    var dateFormated = moment().format(date)
    return moment(dateFormated).locale('cs').format('LL');
  }

}
