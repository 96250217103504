<div class="summary">
  <ul class="container">
    <li>
      <div class="item">
        <img src="../../../assets/images/svg/user.svg">
        <div class="text">
          <h3>Sponzoři</h3>
          <p>Bohatá tombola i výhry</p>
        </div>
      </div>
    </li>
    <li>
      <div class="item">
        <img src="../../../assets/images/svg/time.svg">
        <div class="text">
          <h3>Tradice</h3>
          <p>10+ let existence</p>
        </div>
      </div>
    </li>
    <li>
      <div class="item">
        <img src="../../../assets/images/svg/coffe.svg">
        <div class="text">
          <h3>Zábava</h3>
          <p>Bohatý program</p>
        </div>
      </div>
    </li>
  </ul>
</div>
