import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';

export interface ArticleData {
  title: string;
  htmlContent: string;
  slug:string;
  active: boolean;
  updatedAt: Date;
  thumbnail: thumbnailData;
}
interface thumbnailData{
  url: string;
}
const GET_ARTICLE = gql`
  query GetArticles($slug: String!) {
    articles(where: { slug: $slug }) {
      title
      htmlContent
      thumbnail{
        url
      }
      slug
      active
      updatedAt
    }
  }
`;

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

  private querySubscription: Subscription;
  article: ArticleData;

  constructor(
    private router: Router,
    private title: Title,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_ARTICLE,
      variables: {
        "slug": this.activatedRoute.snapshot.params.slug
      }
    })
      .valueChanges
      .subscribe(({ data }) => {
        if (data.articles?.length > 0) {
          this.article = data.articles[0];

          this.updateTitle(`${this.article.title} | Janovská traktoriáda`);
        } else {
          this.router.navigateByUrl('/')
        }
      });
  }
  public updateTitle(title: string) {
    this.title.setTitle(title);
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
