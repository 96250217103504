<div class="cookies-bar" *ngIf="showCookieBar">
  <div class="content" *ngIf="hideCustomCookie">
    <h2>Nastavení cookies</h2>
    <p>Kliknutím na Povolit vše nám umožníte použití cookies pro funkční, analytické a marketingové účely na tomto
      zařízení. Kliknutím na Podrobné nastavení můžete sami určit jaké cookies je možné zpracovávat, popřípadě jejich
      zpracování úplně zakázat.</p>
    <br>
    <p>
      Více informací o zpracování osobních údajů naleznete v <a href="https://janov-sy.cz/ochrana-osobnich-udaju-2043" target="_blank">Prohlášení o zpracování osobních údajů</a>. Další informace o
      cookies a možnosti změnit jejich nastavení naleznete v <a href="https://janov-sy.cz/zasady-cookies" target="_blank">Zásadách používání cookies</a>.
    </p>
    <a class="accept" (click)="okay('all')">Povolit vše</a>
    <a class="select-cookie" (click)="hideCustomCookie = !hideCustomCookie">Podrobné nastavení</a>
  </div>
  <app-custom-cookie (analyticsEmit)="emittedState($event)" *ngIf="!hideCustomCookie"></app-custom-cookie>
</div>
