<div class="registration-form">
  <div class="container">
    <form (ngSubmit)="register()">
      <div class="tab">
        <div class="is-floating-label">
          <label>Jméno a příjmení</label>
          <input [(ngModel)]="userToRegister.FullName" name="name" required="" type="text"
            pattern="^(?:[a-z0-9!#$%&amp;'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+\=?^_`{|}~-]+)*|'(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])">
        </div>
        <div class="is-floating-label">
          <label>E-mail</label>
          <input [(ngModel)]="userToRegister.Email" name="email" required="" type="email">
        </div>
        <div class="is-floating-label">
          <label>Telefon</label>
          <input [(ngModel)]="userToRegister.PhoneNumber" name="phonenumber" required="" type="tel"
            pattern="^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$">
        </div>
      </div>
      <div class="tab second">
        <div class="is-floating-label">
          <label>Bydliště</label>
          <input name="residence" required="" [(ngModel)]="userToRegister.Residence" type="text" name="place" #place>
        </div>
        <div class="select-option">
          <mat-form-field class="form-field" id="form-field-select">
            <mat-label>Kategorie</mat-label>
            <select matNativeControl [(ngModel)]="userToRegister.Category" name="category">
              <option *ngFor="let category of categories" [value]="category.shortcut.toUpperCase()">
                {{category.title}} ({{category.shortcut}})
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <div class="under">
        <div class="is-floating-label">
          <label>Vaše zpráva</label>
          <textarea [(ngModel)]="userToRegister.Message" name="message"></textarea>
        </div>
        <button type="submit">Odeslat</button>
      </div>
    </form>
  </div>
</div>
