<div class="documents-list">
  <div class="container">
    <h3>Administrativní dokumenty</h3>
    <ul>
      <ng-container *ngFor="let document of hack(documents)">
        <li *ngIf="document.IsAdministrative">
          <a [href]="document.MediaLink[0].url"  target="_blank">
            <div class="icon">
              <ng-container *ngIf="document.Type ==  'document'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14,2H4V22H20V8Z" fill="none" stroke="#000" stroke-miterlimit="10"/><polyline points="14 2 14 8 20 8" fill="none" stroke="#000" stroke-miterlimit="10"/><line x1="16" y1="13" x2="8" y2="13" fill="none" stroke="#000" stroke-miterlimit="10"/><line x1="16" y1="17" x2="8" y2="17" fill="none" stroke="#000" stroke-miterlimit="10"/><polyline points="10 9 9 9 8 9" fill="none" stroke="#000" stroke-miterlimit="10"/></svg>
              </ng-container>
              <ng-container *ngIf="document.Type ==  'image'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect x="5" y="3.5" width="14" height="17" fill="none" stroke="#000"/><polyline points="19 20 7.5 8.5 5 11" fill="none" stroke="#000"/><polyline points="19 14 16.5 11.5 13.5 14.5" fill="none" stroke="#000"/></svg>
              </ng-container>
            </div>
            <div class="text">
              <p>
                {{document.Title}}
              </p>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
    <h3>Pro média</h3>
    <ul>
      <ng-container *ngFor="let document of hack(documents)">
        <li *ngIf="!document.IsAdministrative">
          <a [href]="document.MediaLink[0].url"  target="_blank">
            <div class="icon">
              <ng-container *ngIf="document.Type ==  'document'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14,2H4V22H20V8Z" fill="none" stroke="#000" stroke-miterlimit="10"/><polyline points="14 2 14 8 20 8" fill="none" stroke="#000" stroke-miterlimit="10"/><line x1="16" y1="13" x2="8" y2="13" fill="none" stroke="#000" stroke-miterlimit="10"/><line x1="16" y1="17" x2="8" y2="17" fill="none" stroke="#000" stroke-miterlimit="10"/><polyline points="10 9 9 9 8 9" fill="none" stroke="#000" stroke-miterlimit="10"/></svg>
              </ng-container>
              <ng-container *ngIf="document.Type ==  'image'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect x="5" y="3.5" width="14" height="17" fill="none" stroke="#000"/><polyline points="19 20 7.5 8.5 5 11" fill="none" stroke="#000"/><polyline points="19 14 16.5 11.5 13.5 14.5" fill="none" stroke="#000"/></svg>
              </ng-container>
            </div>
            <div class="text">
              <p>
                {{document.Title}}
              </p>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
