<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-cookies></app-cookies>
<div class="contact-sended" *ngIf="contactSended">
  <div class="content">
    <button class="hamburger" (click)="closeContact()" mat-icon-button color="black">
      <mat-icon fontSet="material-icons-outlined">close</mat-icon>
    </button>
    <h1>Email byl úspěšně odeslán</h1>
    <img src="../assets/gifs/contact-success.gif">
  </div>
</div>
