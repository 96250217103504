import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private title: Title) {
    this.updateTitle(`Galerie | Janovská traktoriáda`);
  }

  public updateTitle(title: string) {
    this.title.setTitle(title);
  }

  ngOnInit() {
  }
}
