<div class="about" id="about">
  <div class="container">
    <div class="content-text">
      <ng-container *ngIf="aboutData != null || aboutData != undefined">
        <h2>{{aboutData?.Title}}</h2>
        <div [innerHTML]="aboutData.htmlContent"></div>
      </ng-container>
      <a routerLink="/galerie" routerLinkActive="active" class="white" mat-stroked-button color="white">
        GALERIE
      </a>
    </div>
    <div class="about-rewards">
      <h2>Fakta v číslech</h2>
      <ng-container *ngIf="listFacts != null || listFacts != undefined">
        <ul>
          <li *ngFor="let item of hack(listFacts)">
            <img src="../../../assets/images/svg/reward.svg">
            <p>
              {{item.Title}}
            </p>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
