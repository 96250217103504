<ng-container *ngIf="articleData != null">
  <div class="post-page">
    <div class="background-container">
      <div class="backg" style="background: url('{{ articleData.thumbnail.url }}')">
      </div>
      <div class="black">
      </div>
    </div>
    <div class="header">
      <div class="content">
        <h3>{{ articleData.title }}</h3>
      </div>
    </div>
    <div class="post container">
      <div class="full-image">
        <img [src]="articleData.thumbnail.url">
      </div>
      <div class="content" [innerHTML]="articleData.htmlContent">
      </div>
      <br>
      <div class="info">
        <span *ngIf="articleData.updatedAt">Vytvořeno:
          {{ returnDate(articleData.updatedAt) }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="articleData == null">
  <div style="height: 100vh; display: flex; align-items:center;justify-content:center;">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
