import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Title } from '@angular/platform-browser';

interface IntroData {
  LinkVideo: string;
  IsEnabled: boolean;
}


const GET_INTRO = gql`
  {
    intro {
      LinkVideo
      IsEnabled
    }
  }
`;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  private querySubscription: Subscription;
  introData: IntroData;

  constructor(
    private apollo: Apollo,
    private title: Title
  ) {
    this.updateTitle(`Závody traktorů | Tradiční akce Janova u Litomyšle`);
  }



  public updateTitle(title: string) {
    this.title.setTitle(title);
  }

  ngOnInit() {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_INTRO
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.introData = data.intro;
      });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

}
