import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import * as moment from 'moment';

const GET_TIME = gql`
  {
    countdown {
      Time
      TimeText
      TextSpecial
      ShowSpecialText
    }
  }
`;

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  private querySubscription: Subscription;
  constructor(private apollo: Apollo) { }
  days: any;
  hours: any;
  minutes: any;
  seconds: any;

  daySpan: any;
  hourSpan: any;
  minuteSpan: any;
  secondSpan: any;

  focused: boolean = false;
  none: false;
  flex: false;
  second: true;
  opacityDown: false;

  timerDate: Date;
  timeText: string;
  specialText: string;
  showSpecialText: boolean;

  ngOnInit() {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_TIME
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.timerDate = data.countdown.Time;
        this.timeText = data.countdown.TimeText;
        this.specialText = data.countdown.TextSpecial;
        this.showSpecialText = data.countdown.ShowSpecialText;
        setInterval(() => {
          const actualDate = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
          const eventDate = moment(this.timerDate);
          const start = moment.utc(actualDate, 'HH:mm');
          const end = moment.utc(eventDate, 'HH:mm');
          if (end.isBefore(start)) {
            end.add(1, 'day');
          }
          const duration = moment.duration(end.diff(start));
          duration.subtract(60, 'minutes');
    
          this.hours = parseInt(moment.utc(+duration).local().format('H'), 0);
          this.minutes = moment.utc(+duration).format('mm');
          this.seconds = moment.utc(+duration).format('ss');
    
          this.days = eventDate.diff(actualDate, 'days');
    
          if (eventDate <= actualDate) {
            this.days = '0';
            this.hours = '0';
            this.minutes = '00';
            this.seconds = '00';
          }
    
          if (this.daySpan === 1) {
            this.daySpan = 'DEN';
          } else if (this.daySpan <= 4 && this.daySpan !== 0) {
            this.daySpan = 'DEN';
          } else {
            this.daySpan = 'DNÍ';
          }
    
          if (this.hourSpan === 1) {
            this.hourSpan = 'HODINA';
          } else if (this.hourSpan <= 4 && this.hourSpan !== 0) {
            this.hourSpan = 'HODINY';
          } else if (this.hourSpan === 0) {
            this.hourSpan = 'HODINA';
          } else {
            this.hourSpan = 'HODIN';
          }
    
          if (this.minuteSpan === 1) {
            this.minuteSpan = 'MINUTA';
          } else if (this.minuteSpan <= 4 && this.minuteSpan !== 0) {
            this.minuteSpan = 'MINUTY';
          } else if (this.minuteSpan === 0) {
            this.minuteSpan = 'MINUT';
          } else {
            this.minuteSpan = 'MINUT';
          }
    
          if (this.secondSpan === 1) {
            this.secondSpan = 'SEKUNDA';
          } else if (this.secondSpan <= 4 && this.secondSpan !== 0) {
            this.secondSpan = 'SEKUNDY';
          } else if (this.secondSpan === 0) {
            this.secondSpan = 'SEKUND';
          } else {
            this.secondSpan = 'SEKUND';
          }
        }, 1000);
      });
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
