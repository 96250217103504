import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GalleryComponent } from './view/gallery/gallery.component';
import { ErrorComponent } from './view/error/error.component';
import { MainComponent } from './view/main/main.component';
import { RegistrationComponent } from './view/registration/registration.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { AdminComponent } from './components/admin/admin.component';
import { ArticleComponent } from './view/article/article.component';

const routes: Routes = [
  { path: 'galerie', component: GalleryComponent },
  { path: 'registrace', component: RegistrationComponent },
  { path: 'dokumenty', component: DocumentsComponent },
  { path: '', component: MainComponent },
  { path: '404', component: ErrorComponent },
  { path: 'admin', component: AdminComponent},
  { path: 'clanek/:slug', component: ArticleComponent},
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
