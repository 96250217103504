<div class="custom-cookie">
  <h3>
    Podrobné nastavení cookies
  </h3>
  <div class="content">
    <p>
      Využíváme soubory cookies, a další technologie pro lepší uživatelský zážitek na webu. Existuje několik druhů
      souborů
      cookies, které využíváme ke sběru dat. Kliknutím na přijmout vše udělujete souhlas využívat všechny typy těchto
      souborů pomocí kterých můžeme naplno využívat nástroje pro personalizaci nabídky služeb.
    </p>
    <br>
    <p>Více informací získáte na <a href="https://janov-sy.cz/zasady-cookies" target="_blank">stránce o souborech cookies</a>. Své preference
      nastavení cookies vyjádříte kliknutím na
      přepínač (přepínač v poloze vpravo vyjadřuje souhlas).
    </p>
    <ul>
      <li>
        <div class="cookie-type">
          <h4>Nezbytné cookies</h4>
          <p>
            Tyto cookies jsou <b>nezbytné</b> pro zajištění základní funkčnosti webových stránek a nelze bez nich
            realizovat
            požadovanou funkcionalitu (např. správné zobrazení stránky, session id, nastavení souhlasů).
          </p>
        </div>
        <div class="operator" style="opacity: 0.7;">
          <button>
            <div class="glider" [class.active]="true"></div>
          </button>
          <p>Vždy povoleno!</p>
        </div>
      </li>
      <li>
        <div class="cookie-type">
          <h4>Výkonnostní cookies</h4>
          <p>
            Výkonnostní cookies shromažďují anonymní informace o navštívených stránkách a o způsobu využití webu. Tyto
            cookies slouží ke zlepšení fungování webových stránek a umožňují nám zejména provádět relevantní úpravy za
            účelem lepší uživatelské zkušenosti.
          </p>
        </div>
        <div class="operator">
          <button (click)="analyticsCookies = !analyticsCookies">
            <div class="glider" [class.active]="analyticsCookies"></div>
          </button>
          <p>{{analyticsCookies ? 'Povoleno' : 'Zakázáno'}}</p>
        </div>
      </li>
      <li>
        <div class="cookie-type">
          <h4>Funkční cookies</h4>
          <p>
            Trvalé cookies používáme pro zapamatování vašeho nastavení preferencí pro příští návštěvu našeho webu a
            umožňují zapamatování vašich předvoleb (např. uživatelské jméno, jazyk). Př. YouTube.
          </p>
        </div>
        <div class="operator">
          <p>Neovlivníme 😊!</p>
        </div>
      </li>
      <li>
        <div class="cookie-type">
          <h4>Reklamní cookies</h4>
          <p>
            Reklamní cookies se využívají k zobrazování cílené reklamy. Bez těchto cookies se vám reklamy budou nadále
            zobrazovat (počet zobrazení reklam nebude nižší), budou však pro vás méně relevantní.
          </p>
        </div>
        <div class="operator">
          <p>Nepoužíváme 😊!</p>
        </div>
      </li>
    </ul>
  </div>
  <div class="operators">
    <a class="save" (click)="saveCookieStatement(analyticsCookies)">Uložit vybrané</a>
    <a class="accept" (click)="saveCookieStatement(true)">Přijmout vše</a>
  </div>
</div>
