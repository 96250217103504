import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-cookie',
  templateUrl: './custom-cookie.component.html',
  styleUrls: ['./custom-cookie.component.scss']
})
export class CustomCookieComponent implements OnInit {
  analyticsCookies: boolean = false;

  @Output() analyticsEmit = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  saveCookieStatement(value){
    this.analyticsEmit.emit(value);
  }
}
