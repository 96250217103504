<div class="history custom-container-history">
  <ul>
    <li *ngFor="let item of data">
      <div class="content">
        <div class="heading">
          <h2>
            {{item.season}}. ročník
          </h2>
          <p>
            {{item.date}}
          </p>
        </div>
        <div class="links">
          <ul class="links-list">
            <li *ngFor="let data of item.data" class="link">
              <a [href]="data.link" target="_blank" [ngClass]="data.type">
                <ng-container *ngIf=" data.type == 'video'">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M22.062,6.927A2.631,2.631,0,0,0,20.234,5.1c-1.637-.439-8.224-.439-8.224-.439S5.439,4.652,3.785,5.1A2.631,2.631,0,0,0,1.957,6.927,27.322,27.322,0,0,0,1.5,12.009a27.287,27.287,0,0,0,.456,5.062A2.631,2.631,0,0,0,3.785,18.9c1.635.44,8.225.44,8.225.44s6.57,0,8.224-.44a2.631,2.631,0,0,0,1.828-1.827,27.364,27.364,0,0,0,.438-5.062A27.406,27.406,0,0,0,22.062,6.927ZM9.907,15.152v-6.3l5.483,3.153Z"
                      style="fill: #fff;" /></svg>
                </ng-container>
                <ng-container *ngIf=" data.type == 'photo'">

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <rect x="4" y="3" width="18" height="14" fill="none" stroke="#fff" />
                    <polyline points="4 6 1 6 1 20 20 20 20 17" fill="none" stroke="#fff" />
                    <polyline points="18.5 17 8.5 7 4 11.5" fill="none" stroke="#fff" />
                    <polyline points="22 14.5 17.5 10 14.5 13" fill="none" stroke="#fff" /></svg>
                </ng-container>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <img [src]="item.themeImage" [alt]="item.season + '. ročník'">
    </li>
  </ul>
</div>
