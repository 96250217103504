<nav [ngClass]="{'sticky': sticky && !menu}" [ngClass]="{'sticky': stickyInput}" #stickyMenu id="menu">
  <div class="container">
    <div class="logo">
      <a routerLink="/" routerLinkActive="active">
        <app-logo  [colored]="menu" [scrolled]="navScrolled || stickyInput" [opened]="menu"></app-logo>
      </a>
    </div>
    <div class="items-link" [ngClass]="{'opened':menu}">
      <ul>
        <li>
          <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">ÚVOD</a>
        </li>
        <li>
          <button (click)="scrollScript('about')">O NÁS</button>
        </li>
        <li>
          <button routerLink="/registrace" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">REGISTRACE</button>
        </li>
        <li>
          <button (click)="scrollScript('sponsors')">SPONZOŘI</button>
        </li>
        <li>
          <a routerLink="/galerie" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >GALERIE</a>
        </li>
        <li>
          <button (click)="scrollScript('contact')">KONTAKT</button>
        </li>
      </ul>
    </div>
    <div class="selection">
      <button class="hamburger" (click)="toggle()" mat-icon-button color="black">
        <mat-icon *ngIf="!menu" fontSet="material-icons-outlined">menu</mat-icon>
        <mat-icon *ngIf="menu" fontSet="material-icons-outlined">close</mat-icon>
      </button>
    </div>
  </div>
</nav>
