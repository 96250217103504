import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Router, NavigationEnd } from '@angular/router';

declare var $: any;

interface ContactDataModel {
  FullName: string;
  Email: string;
  PhoneNumber: number;
  Message: string;
}

const SUBMIT_CONTACT = gql`
  mutation CreateContact($input: createContactInput!) {
    createContact(input: $input) {
      contact {
        Email
      }
    }
  }
`;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  public contactData: ContactDataModel = { Email: "", PhoneNumber: null, FullName: "", Message: "" };

  constructor(
    private apollo: Apollo,
    private router: Router
  ) { }

  ngOnInit(): void {
    $(".is-floating-label input, .is-floating-label textarea")
      .on("focus blur", function (e) {
        $(this)
          .parents(".is-floating-label")
          .toggleClass("is-focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");
  }
  sendEmail() {
    var phoneNumberRegEx = /([+]?\d{1,3}[. \s]?)?(\d{9}?)$/;
    var emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (this.contactData.FullName.length < 8) {
      document.body.style.background = "pink";
    } else if(!phoneNumberRegEx.test(this.contactData.PhoneNumber.toString())){
      document.body.style.background = "blue";
    } else if(!emailRegEx.test(this.contactData.Email)){
      document.body.style.background = "green";
    } else if(this.contactData.Message.length < 5){
      document.body.style.background = "red";
    } else {
      this.apollo.mutate({
        mutation: SUBMIT_CONTACT,
        variables: {
          input: {
            data: {
              ...this.contactData
            }
          }
        }
      }).subscribe(() => {
        this.router.navigate(['/404'], { queryParams: { contactSuccess: 'true' } });
      });
    }
  }
}
