import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  showCookieBar: boolean = false;
  hideCustomCookie: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.checkCookie();
  }
  okay(type) {
    var days = 30;
    var myDate = new Date();
    myDate.setTime(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    if (type == "all") {
      document.cookie =
        "Cookie2022=Accepted; expires=" + myDate["toGMTString"]();
      document.cookie =
        "analyticsCookies=Accepted; expires=" + myDate["toGMTString"]();
    } else {
      document.cookie =
        "Cookie2022=Accepted; expires=" + myDate["toGMTString"]();
      document.cookie =
        "analyticsCookies=False; expires=" + myDate["toGMTString"]();
    }

    this.checkCookie();
  }

  checkCookie() {
    var cookie = document.cookie
      .split(";")
      .map(function (x) {
        return x.trim().split("=");
      })
      .filter(function (x) {
        return x[0] === "Cookie2022";
      })
      .pop();

    if (cookie && cookie[1] === "Accepted") {
      this.showCookieBar = false;
    } else {
      this.showCookieBar = true;
    }
  }

  emittedState(data) {
    this.hideCustomCookie = true;
    data == true ? this.okay("all") : this.okay("no");
  }
}
