import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'janovska-traktoriada';
  contactSended: boolean = false;
  constructor(private router: Router) { }
  closeContact() {
    this.contactSended = !this.contactSended;
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

  onActivate(event) {
    window.scrollTo(0, 0);
    document.body.classList.remove('overflow');

    const urlParams:URLSearchParams = new URLSearchParams(window.location.search);
    if(urlParams.has('contactSuccess')){
        this.contactSended = true;
    }
  }
}
