<div class="articles-list">
  <div class="content-container">
    <h3>Články</h3>
    <div class="recent-posts">
      <ul>
        <ng-container *ngFor="let article of articles">
          <li [ngStyle]="{background: 'url(' + article.thumbnail.url + ')'}">
            <a [routerLink]="'/clanek/'+article.slug">
              <div class="content-text">
                <h1>{{article.title}}</h1>
                <div class="info">
                  {{formatDate(article.updatedAt)}}
                </div>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
      <!--- <div class="button-redirect">
        <a routerLink="/clanky" routerLinkActive="active">Zobrazit všechny články</a>
      </div>-->
    </div>
  </div>
</div>
