import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import * as moment from 'moment';

const GET_DOCUMENTS = gql`
  {
    documents {
      Title
      Type
      IsAdministrative
      MediaLink {
        url
      }
    }
  }
`;

export interface Documents {
  Title: string;
  Type: TypeOfDocument;
  IsAdministrative: boolean;
  MediaLink: MediaSource;
}

interface MediaSource {
  url: string
}

enum TypeOfDocument {
  document = "document",
  image = "image"
}
@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit, OnDestroy {

  documents: Documents[] = [];

  private querySubscription: Subscription;
  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_DOCUMENTS
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.documents = data.documents;
      });
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  hack(val) {
    val = Array.from(val);
    return val;
  }
}
