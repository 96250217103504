import { Component, ViewChild, OnDestroy, ElementRef, OnInit, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Router, NavigationEnd } from '@angular/router';

declare var jQuery: any;

interface Sponsor {
  Title: string;
  ImageLogo: ImageData;
  Link: string;
  Style: string;
}
interface ImageData {
  url: string
}

const GET_SPONSORS = gql`
  {
    sponsors {
      Title
      ImageLogo{
        url
      }
      Link
      Style
    }
  }
`;


@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit, OnDestroy {
  colors: object[];
  @ViewChild('list') listElement: ElementRef;

  width: number;
  transform: string;

  actualTransform: number;
  actualItem: number = 0;
  actualMove: number = 0;

  clicked: boolean = false;

  sponsorsData: Sponsor[] = [];

  data = [
    {
      title: "Obec Janov",
      image: "../../../../../assets/images/sponsors/janov.png",
      link: "http://janov-sy.cz",
      style: "width:60px;"
    },
    {
      title: "Realitní společnost České spořitelny, a.s.",
      image: "../../../../../assets/images/sponsors/realitni-ceske-sporitelny.svg",
      link: "https://www.rscs.cz/",
      style: "width:130px;"

    },
    {
      title: "Kozák Svitavy s.r.o.",
      image: "../../../../../assets/images/sponsors/kozak-svitavy-logo.png",
      link: "http://www.kozak-svitavy.cz/",
      style: "height:35px;"
    },
    {
      title: "Mika a.s.",
      image: "../../../../../assets/images/sponsors/mika.png",
      link: "http://www.mikact.cz",
      style: "height:35px;"
    },
    {
      title: "SOLIDA spol. s r.o. upevňovací systémy",
      image: "../../../../../assets/images/sponsors/solida.svg",
      link: "https://www.solida.cz/",
      style: "width:130px;"
    },
    {
      title: "SWIETELSKY stavební s.r.o.",
      image: "../../../../../assets/images/sponsors/swietelsky.svg",
      link: "https://www.swietelsky.com/cz/",
      style: "width:75px;"

    },
    {
      title: "Kulturní dům a restaurace Na Rychtě",
      image: "../../../../../assets/images/sponsors/restaurace-na-rychte.png",
      link: "https://www.firmy.cz/detail/13059521-restaurace-na-rychte-janov.html",
      style: "width:135px;"
    },
    {
      title: "Železářství Andrlík s.r.o.",
      image: "../../../../../assets/images/sponsors/zelezarstvi-andrlik.png",
      link: "http://www.zelezarstviandrlik.cz/",
      style: "height:35px;"

    },
    {
      title: "DEKTRADE a.s.",
      image: "../../../../../assets/images/sponsors/dektrade.png",
      link: "https://www.dek.cz/",
      style: "height:35px;"

    },
    {
      title: "AMD Svitavy, s.r.o.",
      image: "../../../../../assets/images/sponsors/amd-svitavy.png",
      link: "http://www.amd-svitavy.cz/",
      style: "height:35px;"

    },
    {
      title: "Renata Sodomková – Hospůdka u Sitařů",
      image: "../../../../../assets/images/sponsors/renata-sodomkova.png",
      link: "https://hospudka-u-sitaru.webnode.cz/",
      style: "width:135px;"
    },
    {
      title: "Nekvinda - Zemědělská technika, a.s.",
      image: "../../../../../assets/images/sponsors/nekvinda-edited.png",
      link: "https://www.nekvinda.cz/",
      style: "height:35px;"
    },
    {
      title: "Radek Kmošek – autodoprava & zemní práce",
      image: "../../../../../assets/images/sponsors/radek-kmosek.png",
      link: "https://www.firmy.cz/detail/2433404-radek-kmosek-janov.html",
      style: "width:135px;"
    },
    {
      title: "ZZN Svitavy a.s.",
      image: "../../../../../assets/images/sponsors/zzn-svitavy.png",
      link: "http://www.zznsy.cz/",
      style: "height:35px;"
    },
    {
      title: "EXVALOS, spol. s r.o.",
      image: "../../../../../assets/images/sponsors/exvalos.svg",
      link: "http://www.exvalos.cz/",
      style: "width:105px;"
    },
    {
      title: "GMS Lanškroun, s.r.o.",
      image: "../../../../../assets/images/sponsors/gms-lanskroun-e.png",
      link: "https://www.gms.cz/",
      style: "height:35px;"
    },
    {
      title: "Vladimír Jančev – autodoprava",
      image: "../../../../../assets/images/sponsors/vladimir-jancev.png",
      link: "http://www.vz24.cz/firmy-ve-vz/ostatni-kategorie/vladimir-jancev-4244/",
      style: "width:135px;"
    },
    {
      title: "STIHL Lanškroun, s.r.o.",
      image: "../../../../../assets/images/sponsors/stihl-lanskroun.png",
      link: "http://www.stihl-lanskroun.cz/",
      style: "height:35px;"
    },
    {
      title: "Twentio",
      image: "../../../../../assets/images/sponsors/twentio.svg",
      link: "http://www.twentio.cz",
      style: "height:25px;"
    },
  ]
  private querySubscription: Subscription;
  constructor(
    private sanitizer: DomSanitizer,
    private apollo: Apollo,
  ) { }
  getSaveStyle(data: string): any {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }
  ngOnInit() {

    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_SPONSORS
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.sponsorsData = data.sponsors;
        jQuery('.owl-carousel').owlCarousel({
          loop: true,
          margin: 32,
          nav: false,
          items: this.sponsorsData.length,
          autoWidth: true,
          jsonSuccess: this.customDataSuccess(this.sponsorsData),
          autoplay: true,
          autoplayTimeout: 3500,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 3
            },
            1000: {
              items: 9
            }
          }
        })
      });
  }
  customDataSuccess(data:Sponsor[]) {
    var content = "";
    for (var i in data) {

      var img = data[i].ImageLogo.url;
      var alt = data[i].Title;
      var link = data[i].Link;
      var size = data[i].Style.slice(0, -1) + " !important;" + " object-fit:contain;";

      content += "<a href=\"" + link + "\" target='_blank'><img class='owl-image-content' src=\"" + img + "\" alt=\"" + alt + "\" title=\"" + alt + "\" style=\"" + size + "\"> </a> "
    }
    jQuery(".owl-carousel").html(content);
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
  hack(val) {
    val = Array.from(val);
    return val;
  }
}
