import { Component, ElementRef, ViewChild, HostListener, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() stickyInput: boolean = false;

  @ViewChild('stickyMenu') menuElement: ElementRef;

  @Input() disabled: boolean = false;

  sticky = false;
  elementPosition: any;

  navScrolled = false;
  menu = false;

  constructor(private router: Router){}

  toggle() {
    this.menu = !this.menu;

    if (this.menu != true) {
      document.body.classList.remove("overflow");
    } else {
      document.body.classList.add("overflow");
      window.scrollTo(0, 0);
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }
  scrollScript(component) {

    if(768 >= window.innerWidth){
      this.menu = !this.menu;
      document.body.classList.remove("overflow");
    }

    if(component == 'about' && document.getElementById(component) == null){
      this.router.navigate(['/'])
      setTimeout(() => {
        var element = document.getElementById(component);
        var body = document.body.getBoundingClientRect().top;
        var positionRect = element.getBoundingClientRect().top;
        var position = positionRect - body;
        window.scrollTo({
          top: position,
          behavior: "smooth"
        });
      }, 1000);
    } else{
      var element = document.getElementById(component);
      var body = document.body.getBoundingClientRect().top;
      var positionRect = element.getBoundingClientRect().top;
      var position = positionRect - body;
      window.scrollTo({
        top: position,
        behavior: "smooth"
      });
    }
  }
  @HostListener('window:scroll')
  onScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.elementPosition && this.stickyInput == false) {
      this.sticky = true;
      this.navScrolled = true;
    } else {
      this.sticky = false;
      this.navScrolled = false;
    }
  }
}
