<span *ngIf="!showSpecialText">{{timeText}}</span>
<h1 *ngIf="!showSpecialText">NENECHTE SI UJÍT NADCHÁZEJÍCÍ AKCI</h1>
<p *ngIf="showSpecialText" style="font-size: 18px; max-width: 500px; margin-bottom: 6rem; color: #fff; background: #ff000052; padding: 1rem; border-radius: 5px;">
{{specialText}}
</p>
<ul *ngIf="!showSpecialText" class="timer">
  <li>
    <h2>{{days}}</h2>
    <p>{{daySpan}}</p>
  </li>
  <li>
    <h2>{{hours}}</h2>
    <p>{{hourSpan}}</p>
  </li>
  <li>
    <h2>{{minutes}}</h2>
    <p>{{minuteSpan}}</p>
  </li>
  <li>
    <h2>{{seconds}}</h2>
    <p>{{secondSpan}}</p>
  </li>
</ul>
