import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// tslint:disable-next-line: max-line-length
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCarouselModule } from '@ngbmodule/material-carousel';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { GalleryComponent } from './view/gallery/gallery.component';
import { TimerComponent } from './components/timer/timer.component';
import { MainComponent } from './view/main/main.component';
import { SummaryComponent } from './components/summary/summary.component';
import { LogoComponent } from './components/logo/logo.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { HistoryComponent } from './components/history/history.component';
import { RegistrationComponent } from './view/registration/registration.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { IntroComponent } from './components/intro/intro.component';
import { GraphQLModule } from './graphql.module';
import { AdminComponent } from './components/admin/admin.component';
import { ArticlesListComponent } from './components/articles-list/articles-list.component';
import { ArticlesComponent } from './view/articles/articles.component';
import { ArticleComponent } from './view/article/article.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ErrorComponent } from './view/error/error.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { CustomCookieComponent } from './components/custom-cookie/custom-cookie.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    GalleryComponent,
    TimerComponent,
    MainComponent,
    SummaryComponent,
    LogoComponent,
    AboutComponent,
    FooterComponent,
    CarouselComponent,
    CategoriesComponent,
    SponsorsComponent,
    HistoryComponent,
    RegistrationComponent,
    DocumentsComponent,
    DocumentsListComponent,
    RegistrationFormComponent,
    PageHeadComponent,
    IntroComponent,
    AdminComponent,
    ArticlesListComponent,
    ArticlesComponent,
    ArticleComponent,
    ArticleDetailComponent,
    ContactFormComponent,
    ErrorComponent,
    CookiesComponent,
    CustomCookieComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCarouselModule.forRoot(),
    GraphQLModule,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Le-mB8aAAAAAMshplQQT_PqtCm0AaIwBohEev6X' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
