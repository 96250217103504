import { Component, OnInit } from '@angular/core';
import { PageHead } from "../../../app/components/page-head/page-head.component"
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  pageHead: PageHead = { title: 'Registrace', background: '../../../assets/images/carousel/3.jpg' }
  constructor(private title: Title) {
    this.updateTitle(`Registrace | Janovská traktoriáda`);
  }

  public updateTitle(title: string) {
    this.title.setTitle(title);
  }
  ngOnInit(): void {
  }

}
