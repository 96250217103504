import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ArticleData } from '../../view/article/article.component';
import * as moment from 'moment';
const moments = (moment as unknown) as { suppressDeprecationWarnings: boolean; };
moments.suppressDeprecationWarnings = true;

const GET_ARTICLES = gql`
{
  articles {
    title
    htmlContent
    slug
    active
    updatedAt
    thumbnail{
      url
    }
  }
}
`;

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent implements OnInit, OnDestroy {

  private querySubscription: Subscription;
  public articles: ArticleData[] = [];
  public sortedArticles: ArticleData[];
  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_ARTICLES
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.articles = data.articles.slice().sort((a, b) => {
          return moment(b.updatedAt).unix() - moment(a.updatedAt).unix()
        });
      });
  }
  
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  public formatDate(date) {
    var dateFormated = moment().format(date)
    return moment(dateFormated).locale('cs').format('LL');
  }
}
