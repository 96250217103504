<div class="carousel-wrapper">
  <div class="carousel-text">
    <h3>GALERIE - PŘEHLED VŠECH ROČNÍKU JANOVSKÉ TRAKTORIÁDY</h3>
    <h1>GALERIE</h1>
    <p>"Popularita Janovské traktoriády v okamžiku jejího 10. výročí vystoupila až navrchol, jednak nejvyšším počtem
      závodníků, tak soutěžemi, které jsou každoročně jiné, krom nejpopulárnější - bahno"</p>
  </div>
  <mat-carousel #matCarousel [timings]="timings" [autoplay]="autoplay" [interval]="interval" [loop]="loop" class="mat-carousel"
    [hideArrows]="hideArrows" [hideIndicators]="hideIndicators" [useKeyboard]="useKeyboard"
    [useMouseWheel]="useMouseWheel" maintainAspectRatio="maintainAspectRatio" slideHeight="slideHeight">
    <mat-carousel-slide *ngFor="let slide of slides;" [image]="slide.url" [overlayColor]="overlayColor"
      [hideOverlay]="hideOverlay">
    </mat-carousel-slide>
  </mat-carousel>
</div>
