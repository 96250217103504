<app-menu></app-menu>
<app-header></app-header>
<app-about></app-about>
<app-categories></app-categories>
<div class="documents">
  <a routerLink="/dokumenty">
    <h4>
      Dokumenty
    </h4>
  </a>
</div>
<app-articles-list></app-articles-list>
<ng-container *ngIf="introData != null">
  <app-intro *ngIf="introData.IsEnabled" [iframeSrcTmp]="introData.LinkVideo"></app-intro>
</ng-container>
<app-sponsors></app-sponsors>
<app-footer></app-footer>
