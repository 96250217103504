<div class="page-head">
  <div class="overlay">
    <div class="container">
      <div class="block">
        <h1>{{dataHeader.title}}</h1>
      </div>
    </div>
  </div>
  <div class="image">
    <img [src]="dataHeader.background" />
  </div>
</div>
