import { Component, OnInit, Input } from '@angular/core';

export interface PageHead {
  title: string;
  background: string;
}
@Component({
  selector: 'app-page-head',
  templateUrl: './page-head.component.html',
  styleUrls: ['./page-head.component.scss']
})
export class PageHeadComponent implements OnInit {
  @Input() dataHeader: PageHead;
  constructor() { }

  ngOnInit(): void {
  }

}
