import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { Categories } from "../categories/categories.component"
import { Apollo, gql } from 'apollo-angular';
import { Router, NavigationEnd } from '@angular/router';

declare var SMap: any;
declare var jQuery: any;

interface RegistrationData {
  FullName: string;
  Email: string;
  PhoneNumber: number;
  Residence: string;
  Category: RegistrationCategory;
  Message: string;
}

enum RegistrationCategory {
  MD = "MD",
  MA = "MA",
  MM = "MM",
  BUG = "BUG"
}

const SUBMIT_REGISTRATION = gql`
  mutation CreateRegistration($input: createRegistrationInput!) {
    createRegistration(input: $input) {
      registration {
        Email
      }
    }
  }
`;

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit, AfterViewInit {

  @ViewChildren('place') placeInputs: QueryList<ElementRef<HTMLInputElement>>;
  categories: Categories[] = [
    {
      shortcut: 'MD',
      title: 'Motor diesel',
    },
    {
      shortcut: 'MA',
      title: 'Motor benzínový',
    },
    {
      shortcut: 'MM',
      title: 'Motor motocykl',
    },
    {
      shortcut: 'BUG',
      title: 'Bugina',
    }
  ];

  userToRegister: RegistrationData = { FullName: "", Category: null, Email: "", Message: "", PhoneNumber: null, Residence: "" };

  constructor(
    private apollo: Apollo,
    private router: Router
  ) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('kategorie');
    if (myParam != null) {
      setTimeout(() => {
        document.getElementById("form-field-select").classList.remove("ng-untouched");
        document.getElementById("form-field-select").classList.add("ng-touched");
      }, 500);
      this.userToRegister.Category = RegistrationCategory[myParam.toUpperCase()];
    }
  }

  ngAfterViewInit(): void {
    this.setSuggest();
    this.placeInputs.changes.subscribe(() => this.setSuggest());
  }

  setSuggest(): void {
    const suggestList: any = [];

    this.placeInputs.toArray().forEach(p => {
      suggestList.push(new SMap.Suggest(p.nativeElement, {
        provider: new SMap.SuggestProvider({
          updateParams: (params: any) => {
            params.enableCategories = 1;
            params.type = 'municipality|street';
            params.category = 'address_cz,municipality_cz,district_cz,region_cz,street_cz,poi_cz,quarter_cz,area_cz';
            params.bounds = '48.5370786,12.0921668|51.0746358,18.8927040';
            params.lang = 'cs';
            params.langFilter = 'cs';
          },
        })
      }));
    });
    for (let i = 0; i < suggestList.length; i++) {
      suggestList[i].addListener('suggest', ({ data }) => {
        const { phrase, secondRow } = data;
        this.userToRegister.Residence = phrase + " " + secondRow;
      });
    }
  }

  register() {
    this.apollo.mutate({
      mutation: SUBMIT_REGISTRATION,
      variables: {
        input: {
          data: {
            ...this.userToRegister
          }
        }
      }
    }).subscribe(({ data }) => {
      this.router.navigate(['/'], { queryParams: { contactSuccess: 'true' } });
    });
  }
}
