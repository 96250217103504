import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Router, NavigationEnd } from '@angular/router';

interface FactsList {
  Title: string;
}

interface AboutSection {
  Title: string;
  htmlContent: string;
}

const GET_FACTS_ABOUT = gql`
  {
    factsAbouts {
      Title
    }
  }
`;

const GET_ABOUT_TEXT = gql`
  {
    about {
      Title
      htmlContent
    }
  }
`;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  private querySubscription: Subscription;
  listFacts: FactsList[];
  aboutData: AboutSection;

  constructor(
    private apollo: Apollo,
    private router: Router
  ) { }

  ngOnInit() {
    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_FACTS_ABOUT
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.listFacts = data.factsAbouts;
      });

    this.querySubscription = this.apollo.watchQuery<any>({
      query: GET_ABOUT_TEXT
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.aboutData = data.about;
      });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  hack(val) {
    val = Array.from(val);
    return val;
  }
}
