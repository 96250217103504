<header>
  <div class="content-header">
    
    <app-timer></app-timer>
    <div class="links">
      <a routerLink="registrace" mat-raised-button color="primary">
        REGISTROVAT SE
      </a>
      <a href="https://facebook.com/janovska.traktoriada" target="_blank" class="white" mat-stroked-button color="white">
        PŘIPOJIT SE
      </a>
    </div>
  </div>
  <div class="summary">
    <app-summary></app-summary>
  </div>
</header>
