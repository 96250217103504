<div class="categories">
  <div class="content">
    <ul>
      <li *ngFor="let category of categories; let i  = index">
        <a mat-flat-button color="accent" [routerLink]="['registrace']"
          [queryParams]="{kategorie:category.shortcut.toLowerCase()}">
          <p>
            Kategorie {{i+1}}.
          </p>
          <h3>
            Kategorie {{category.shortcut}}
          </h3>
          <p>
            {{category.title}}
          </p>
        </a>
      </li>
    </ul>
  </div>
</div>
