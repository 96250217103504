import { Component, OnInit } from '@angular/core';
import { PageHead } from "../../../app/components/page-head/page-head.component"
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  pageHead: PageHead = { title: 'Dokumenty', background: '../../../assets/images/season/rocnik_9.jpg' }

  constructor(private title: Title) {
    this.updateTitle(`Dokumenty | Janovská traktoriáda`);
  }

  public updateTitle(title: string) {
    this.title.setTitle(title);
  }

  ngOnInit(): void {
  }

}
