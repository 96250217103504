import { Component, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngbmodule/material-carousel';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  public slides: { url: string }[] = [
    { url: '/assets/images/carousel/2.jpg' },
    { url: '/assets/images/carousel/3.jpg' },
    { url: '/assets/images/carousel/4.jpg' },
    { url: '/assets/images/carousel/1.jpg' },
  ];

  public slide = this.slides.length;
  public timings = '250ms ease-in';
  public autoplay = true;
  public interval = 5000;
  public loop = true;
  public hideArrows = false;
  public hideIndicators = false;
  public maintainAspectRatio = false;
  public slideHeight = '10vh';
  public overlayColor = '#00000090';
  public hideOverlay = false;
  public useKeyboard = true;
  public useMouseWheel = false;
  public log: string[] = [];

  constructor(private cdr: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
